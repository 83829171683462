<template>
	<v-container pa-1>
		<v-layout row wrap>
			<v-flex xs12 md6  pr-2>
				<v-text-field label="Наименование" v-model="model.name" />
			</v-flex>
			<v-flex xs12 md6>
				<v-autocomplete
					standard
					v-model="model.scope_id"
					label="Сфера деятельности"
					:items="scopes"
					item-text="name"
					item-value="id"
				/>
			</v-flex>
			<v-combobox
				v-model="model.employment_types"
				:items="employment_types"
				label="Тип занятости"
				item-text="name"
				chips
				clearable
				solo
				multiple
			>
				<template slot="selection" slot-scope="data">
					<v-chip
						:selected="data.selected"
						close
						@input="removeEmploymentType(data.item)"
					>
						<strong>{{ data.item.name }}</strong>
					</v-chip>
				</template>
			</v-combobox>
			<v-combobox
				v-model="model.schedule_types"
				:items="schedule_types"
				label="График работы"
				item-text="name"
				chips
				clearable
				solo
				multiple
			>
				<template slot="selection" slot-scope="data">
					<v-chip
						:selected="data.selected"
						close
						@input="removeScheduleType(data.item)"
					>
						<strong>{{ data.item.name }}</strong>
					</v-chip>
				</template>
			</v-combobox>
			<v-flex xs12 md6 pr-2>
				<v-text-field label="Зарплата" v-model="model.salary" />
			</v-flex>
			<v-flex xs12 md6>
				<v-select label="Валюта"
					standard
					v-model="model.currency_id"
					:items="currencies"
					item-text="name"
					item-value="id"
					:menu-props="{ offsetY : true }"
				>
					<template slot="item" slot-scope="{ item }">
						{{ item.name }}
					</template>
					<template slot="selection" slot-scope="{ item }">
						{{ item.name }}
					</template>
				</v-select>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	name: 'app-position-dialog',

	props: {
		model: {}
	},

	remoteData: {
		allScopes: '/api/v1/main/guides/scopes/short',
		allEmploymentTypes: '/api/v1/main/guides/employment_types/short',
		allScheduleTypes: '/api/v1/main/guides/schedule_types/short',
		allCurrencies: '/api/v1/main/guides/currencies/short'
	},

	data() {
		return {
			allScopes: [],
			allEmploymentTypes: [],
			allScheduleTypes: [],
			allCurrencies: []
		}
	},

	computed: {
		scopes() {
			return this.allScopes;
		},
		employment_types() {
			return this.allEmploymentTypes;
		},
		schedule_types() {
			return this.allScheduleTypes;
		},
		currencies() {
			return this.allCurrencies;
		}
	},

	mounted() {
		if (!this.model.scope_id) {
			this.model.scope_id = '1035a454-eb9a-11e9-81b4-2a2ae2dbcce4';
		}
		if (!this.model.currency_id) {
			this.model.currency_id = 'b41f0fba-0900-4e0f-94d1-8095bcc51175';
		}
	},

	methods: {
		async prepare() {
			this.model.scope_name = this.allScopes.find(el => el.id === this.model.scope_id).name;
			this.model.currency_name = this.allCurrencies.find(el => el.id === this.model.currency_id).name;
			this.model.employment_type_names = this.model.employment_types ? this.model.employment_types.map(r => r.name).join(', ') : null;
			this.model.schedule_type_names = this.model.schedule_types ? this.model.schedule_types.map(r => r.name).join(', ') : null;
			return true;
		},
		removeEmploymentType (item) {
			this.model.employment_types.splice(this.model.employment_types.indexOf(item), 1);
			this.model.employment_types = [...this.model.employment_types];
		},
		removeScheduleType (item) {
			this.model.schedule_types.splice(this.model.schedule_types.indexOf(item), 1);
			this.model.schedule_types = [...this.model.schedule_types];
		}
	}
}
</script>