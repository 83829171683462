<template>
	<v-container pa-1>
		<v-layout row wrap>
			<v-flex xs12 md6>
				<v-text-field label="Год окончания" v-model="model.year" />
			</v-flex>
			<v-flex xs12 md6>
				<v-select label="Уровень образования"
					v-model="model.education_level_id"
					:items="education_levels"
					item-text="name"
					item-value="id"
					:menu-props="{ offsetY : true }"
				>
					<template slot="item" slot-scope="{ item }">
						{{ item.name }}
					</template>
					<template slot="selection" slot-scope="{ item }">
						{{ item.name }}
					</template>
				</v-select>
			</v-flex>
			<v-flex xs12>
				<v-text-field  label="Название учебного заведения" v-model="model.name" />
			</v-flex>
			<v-flex xs12>
				<v-text-field  label="Описание" v-model="model.description" />
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	name: 'app-experience-dialog',

	props: {
		model: {}
	},

	remoteData: {
		allEducationLevels: '/api/v1/main/guides/education_levels/short',
	},

	data() {
		return {
			allEducationLevels: []
		}
	},

	computed: {
		education_levels() {
			return this.allEducationLevels;
		}
	},

	mounted() {
		if (!this.model.education_level_id) {
			this.model.education_level_id = '7fdb01fa-f761-4269-8adb-3ea249ccaf97';
		}
	},

	methods: {
		async prepare() {
			this.model.education_level_name = this.allEducationLevels.find(el => el.id === this.model.education_level_id).name;
			return true;
		}
	}
}
</script>